<template>
    <div>
         <br /><br />
        <div v-for="(item) in risks" :key="item.title">
            <div>{{item.title}}</div>
             <br />
            <div>{{item.content}}</div>
             <br />
        </div>
    </div>
</template>

<script>
export default {
  name: 'GeneralRiskWarning',
  data() {
    return {
      risks: [
        {
          title: 'A. Explanation of this Risk Warning',
          content: 'All terms used in this notice carry the same meaning and interpretation as defined in the Inst Money Terms of Use.',
        },
        {
          title: 'B. Inst Money Services',
          content: 'This notice aims to inform users about the inherent risks associated with utilizing Inst Money services. Each service provided by Inst Money has its own set of unique risks, and this notice provides a general overview of these risks.',
        },
        {
          title: 'C. No Personal Advice',
          content: 'Inst Money does not offer personalized advice regarding its products or services. While factual information, transaction procedures, and potential risks may be provided, the decision to use Inst Money products or services ultimately rests with the user. Communications or information from Inst Money should not be construed as investment, financial, or trading advice.',
        },
        {
          title: 'D. No Monitoring',
          content: 'Inst Money does not act as a broker, intermediary, agent, or advisor for users. There is no fiduciary relationship, and Inst Money does not monitor the alignment of user activities with their financial goals. It is the user\'s responsibility to assess whether their financial resources are suitable for activities undertaken with Inst Money and to determine their risk tolerance.',
        },
        {
          title: 'E. No Tax, Regulatory, or Legal Advice',
          content: 'The taxation of digital assets is uncertain, and users are responsible for determining their tax liabilities related to transactions through Inst Money services. Inst Money does not provide legal or tax advice, and users are encouraged to seek independent advice for any doubts about their tax status or obligations.',
        },
        {
          title: 'F. Market Risks',
          content: 'Digital asset trading is subject to high market risk and volatility. Changes in value can be significant and occur rapidly without warning. Past performance is not indicative of future results, and the value of investments can go down as well as up.',
        },
        {
          title: 'G. Liquidity Risk',
          content: 'Digital assets may have limited liquidity, making it difficult or impossible for users to sell or exit a position when desired. Liquidity challenges can arise at any time, especially during periods of rapid price movements.',
        },
        {
          title: 'H. Fees & Charges',
          content: 'Inst Money\'s fees and charges are outlined [here](insert link). Inst Money reserves the discretion to update fees periodically. It is essential to be aware of all applicable costs and charges, as they will impact the gains generated from using Inst Money services.',
        },
        {
          title: 'I. Availability Risk',
          content: 'Inst Money does not guarantee the continuous availability of its services. Unplanned service outages or network congestion may occur, affecting the user\'s ability to perform digital asset transactions.',
        },
        {
          title: 'J. Third-Party Risk',
          content: 'Third parties, such as payment providers and custodians, may be involved in providing Inst Money services. Users may be subject to the terms and conditions of these third parties, and Inst Money is not liable for any losses caused by them.',
        },
        {
          title: 'K. Security Risk',
          content: 'It is not feasible for Inst Money to eliminate all security risks. Users are responsible for safeguarding their Inst Money account password, and they may be held accountable for all transactions under their account, whether authorized or not.',
        },
        {
          title: 'L. Risks related to Digital Assets',
          content: 'Given the nature of digital assets and their underlying technologies, intrinsic risks include faults, hacks, protocol failures, irreversibility of transactions, and technological obsolescence.',
        },
        {
          title: 'M. Monitoring Risks',
          content: 'Digital asset markets operate 24/7, and rapid price changes may occur at any time, including outside normal business hours.',
        },
        {
          title: 'N. Communication Risks',
          content: 'Electronic communications with Inst Money may fail, experience delays, lack security, or fail to reach the intended destination.',
        },
        {
          title: 'O. Currency',
          content: 'Currency exchange fluctuations will impact gains and losses.',
        },
        {
          title: 'P. Legal Risk',
          content: 'Changes in laws and regulations may materially affect the value of digital assets. This risk is unpredictable and may vary across different markets.',
        },
      ],
    };
  },
};
</script>

<!-- eslint-disable -->
<template>
  <DocPage :titles="titles">
    <div>
      <h1 id="Privacy">Privacy</h1>
      INST (defined below under “Our relationship to you”) is committed to
      protecting the privacy of visitors to our websites and our customers. This
      Privacy Policy describes how we handle your personal data when you access
      our services, which include our content on the websites located at
      inst.money or any other websites, pages, features, or content we own or
      operate (collectively, the "Site(s)"), or any INST API or third party
      applications relying on such an API, and related services (referred to
      collectively hereinafter as "Services").<br /><br />
      We may change this Privacy Policy in our sole discretion at any time by
      publishing an updated version here. Any changes to this Privacy Policy
      will take effect and be binding on you from the time of posting the
      updated version here. Therefore, we encourage you to periodically review
      any changes that may be made. Your continued use of our Service
      constitutes your agreement to be bound by any changes to this Privacy
      Policy. If you do not agree with this Privacy Policy, you are not
      authorized to access or use our Service.<br /><br />
      If you have any questions about this Policy, please send them to
      <a href="mailto:support@inst.money"> support@inst.money </a><br /><br />
      <h2 id="Scope">Scope</h2>
      <br /><br />
      This Privacy Policy applies when you access or use the Service. This
      Privacy Policy explains what information may be shared and transferred on
      INST. It also sets out your rights in relation to your information and how
      you can contact us if you have any questions.<br /><br />
      <h2 id="Information You Share">Information You Share</h2>
      <br /><br />
      You may share your Information through various forms and in various places
      within our Service. The type of Information you may share about you
      includes but is not limited to:<br />
      a. PProfile information, such as your full name, gender, date of birth,
      email addresses, website information, telephone number, postal address,
      and profile photographs;<br />
      b. Educational and employment background;<br />
      c. Contacts and connections;<br />
      d. Social media handles and other social media profile information that
      you make available to us or to the public, including Facebook, Twitter,
      Linkedin, Google and etc;<br />
      e. Digital wallet information, and financial information such as credit
      card, bank account or other payment details;<br />
      f. Details of any Service that we provide to you;<br />
      g. Communication preferences and records of any communications.<br /><br />
      <h2 id="Use of Information">Use of Information</h2>
      <br /><br />
      Your Information may be used for the purposes including but not limited
      to:<br />
      a. To verify your identity when you are dealing on INST and/or to
      determine your eligibility for any of our Service;<br />
      b. To respond to your communications to us;<br />
      c. To notify you of any changes to the terms of use for any Service;<br />
      d. To enable us to provide our Service and personalize your experience;<br />
      e. To detect, diagnose, and fix problems affecting our Service and to
      improve our Service;<br />
      f. To process transactions;<br />
      g. To comply with applicable legal obligations and other rules,
      regulations, codes of practice, and orders and directions of competent
      governmental authorities that we are obligated to follow, and to manage
      related risks;<br />
      h. To prevent, detect, investigate, and prosecute threats, fraud,
      misconduct, or unlawful activity;<br />
      i. To create, develop, operate, deliver, and improve our Service;<br />
      j. To enforce our agreements and resolve disputes;<br /><br />
      <br />
      <h2 id="How we use your personal information">
        How we use your personal information
      </h2>
      <br /><br />
      How we use your personal information<br />
      We may use your information in the following ways and for the following
      purposes:<br />
      (1) To maintain legal and regulatory compliance<br />
      INST needs to process your personal information in order to comply with
      anti-money laundering and security laws. In addition, when you seek to
      link a bank account to your INST account, we may require you to provide
      additional information which we may use in collaboration with service
      providers acting on our behalf to verify your identity or address, and/or
      to manage risk as required under applicable law. We also process your
      personal information in order to help detect, prevent, and mitigate fraud
      and abuse of our Services and to protect you against account compromise or
      funds loss. If you do not provide personal information required by law, we
      will have to close your account.<br />
      (2) To provide INST's Services<br />
      We process your personal information to provide Services to you. For
      example, when you want to make transactions on our platform, we require
      certain information such as your identification, contact and payment
      information. Third parties that we use such as identity verification
      services may also access and/or collect your personal information when
      providing identity verification and/or fraud prevention services. In
      addition, we may need to collect fees based on your use of our Services.
      We collect information about your account usage and closely monitor your
      interactions with our Services. The consequences of not processing your
      personal information for such purposes is the termination of your
      account.<br />
      (3) To provide communications and customer services<br />
      According to your preferences and in compliance with applicable law, we
      may send you marketing communications to inform you about events, to
      deliver targeted marketing and to share promotional offers. If you are a
      new customer, we will contact you by electronic means for marketing
      purposes only if you have consented to such communication. If you do not
      want us to send you marketing communications, please go to your account
      settings to opt-out or submit a request via support@inst.money We may send
      you service updates regarding administrative or account-related
      information, security issues, or other transaction-related information.
      These communications are important to share developments relating to your
      account that may affect how you can use our Services. You cannot opt-out
      of receiving critical service communications. We also process your
      personal information when you contact us to resolve any questions,
      disputes, collect fees, or to troubleshoot problems. Without processing
      your personal information for such purposes, we cannot respond to your
      requests and ensure your uninterrupted use of the Services.<br />
      (4) In our legitimate business interests<br />
      Sometimes the processing of your personal information is necessary for our
      legitimate business interests, such as:<br />
      ▪ quality control and staff training;<br />
      ▪ to enhance security, monitor and verify identity or service access, and
      to combat spam or other malware or security risks;<br />
      ▪ research and development purposes;<br />
      ▪ to enhance your experience of our Services and Sites; or to facilitate
      corporate acquisitions, mergers, or transactions.<br /><br />
      <br />
      <h2 id="Legal bases for processing your information">
        Legal bases for processing your information
      </h2>
      <br /><br />
      For individuals located in the European Economic Area, United Kingdom or
      Switzerland at the time their personal data is collected, we rely on legal
      bases for processing your information under the relevant data protection
      legislation. These bases mean we will only process your data where we are
      legally required to, where processing is necessary to perform any
      contracts we entered with you (or to take steps at your request prior to
      entering into a contract with you), for our legitimate interests to
      operate our business, to protect INST's or your property rights, or where
      we have obtained your consent to do so. We will not use your personal
      information for purposes other than those purposes we have disclosed to
      you, without your permission.<br /><br />
      <br />
      <h2 id="How we protect and store personal information">
        How we protect and store personal information
      </h2>
      <br /><br />
      INST implements and maintains reasonable measures to protect your
      information. Customer files are protected with safeguards according to the
      sensitivity of the relevant information. Reasonable controls (such as
      restricted access) are placed on our computer systems. Physical access to
      areas where personal information is gathered, processed or stored is
      limited to authorized employees.<br /><br />
      We may store and process all or part of your personal and transactional
      information, including certain payment information, such as your encrypted
      bank account and/or routing numbers, in the US and elsewhere in the world
      where our facilities or service providers are located. We protect your
      personal information by maintaining physical, electronic, and procedural
      safeguards in compliance with the applicable laws and regulations.<br /><br />
      As a condition of employment, INST’s employees are required to follow all
      applicable laws and regulations, including in relation to data protection
      law. Access to sensitive personal information is limited to those
      employees who need to it to perform their roles. Unauthorized use or
      disclosure of confidential customer information by a INST employee is
      prohibited and may result in disciplinary measures.<br /><br />
      When you contact a INST employee about your file, you may be asked for
      some personal details. This type of safeguard is designed to ensure that
      only you, or someone authorized by you, has access to your file. You also
      play a vital role in protecting your own personal information. When
      registering with our Services, choose a password of sufficient length and
      complexity, don’t reveal it to any third-parties and immediately notify us
      if you become aware of any unauthorized access to or use of your
      account.<br /><br />
      <br />
      <h2 id="Sharing and Cross-Border Transfer of Information">
        Sharing and Cross-Border Transfer of Information
      </h2>
      <br /><br />
      Your Information may be shared and cross-border transferred in the
      following ways:<br />
      a. With authorities in response to a request for information if we believe
      disclosure is in accordance with, or required by, any applicable law,
      regulation or legal process;<br />
      b. With the service providers on INST if you link your account to their
      services (you may cancel the link with such third party online services at
      any time);<br />
      c. With the service providers on INST where aggregate information is
      disclosed which cannot be reasonably be used to identify you;<br />
      d. With any other person where certain information has been displayed
      publicly; Your Information may be transferred to other countries or
      districts for processing, and by using the Service you consent to the
      transfer, use, and processing of your Information to countries or
      districts outside of Singapore.<br /><br />
      We will not sell, resell, lease, or license your Information to any third
      parties. However, you mayauthorize the service providers on INST to use
      your personal data only as instructed by you in a manner that is necessary
      to provide these services to you.
      <br /><br />
      <h2 id="General Risk Warning">General Risk Warning</h2>
      <GeneralRiskWarning />
      <h2 id="Your privacy rights">Your privacy rights</h2>
      <br /><br />
      Depending on applicable law of where you reside, you may be able to assert
      certain rights related to your personal information. These rights
      include:<br /><br />
      ▪ the right to obtain information regarding the processing of your
      personal information and access to the personal information which we hold
      about you;<br />
      ▪ the right to withdraw your consent to the processing of your personal
      information at any time. Please note, however, that we may still be
      entitled to process your personal information if we have another
      legitimate reason for doing so. For example, we may need to retain
      personal information to comply with a legal obligation;<br />
      ▪ in some circumstances, the right to receive some personal information in
      a structured, commonly used and machine-readable format and/or request
      that we transmit those data to a third party where this is technically
      feasible. Please note that this right only applies to personal information
      which you have provided directly to INST;<br />
      ▪ the right to request that we rectify your personal information if it is
      inaccurate or incomplete;<br />
      ▪ the right to request that we erase your personal information in certain
      circumstances. Please note that there may be circumstances where you ask
      us to erase your personal information, but we are legally entitled to
      retain it;<br />
      ▪ the right to object to, or request that we restrict, our processing of
      your personal information in certain circumstances. Again, there may be
      circumstances where you object to, or ask us to restrict, our processing
      of your personal information but we are legally entitled to refuse that
      request;<br />
      ▪ the right to lodge a complaint with the relevant data protection
      regulator if you think that any of your rights have been infringed by
      us.<br /><br />
      You can exercise your rights by contacting us using the details listed
      below. Further information about your rights may be obtained by contacting
      the supervisory data protection authority located in your jurisdiction.<br /><br />
      Contact Us<br /><br />
      If you have any questions about this Privacy Policy or our processing of
      your information, please contact our Data Protection Officer at
      <a href="mailto:support@inst.money"> support@inst.money </a>
    </div>
  </DocPage>
</template>

<script>
import DocPage from '@/views/DocPage.vue';
import GeneralRiskWarning from './components/GeneralRiskWarning.vue';
/**
 * @author yuanzeyu
 * @date 2020-02-11
 * @desc
 */
export default {
  name: 'Privacy',
  components: {
    DocPage,
    GeneralRiskWarning,
  },
  data() {
    return {
      titles: [
        'Privacy',
        'Scope',
        'Information You Share',
        'Use of Information',
        'How we use your personal information',
        'Legal bases for processing your information',
        'How we protect and store personal information',
        'Sharing and Cross-Border Transfer of Information',
        'General Risk Warning',
        'Your privacy rights',
      ],
    };
  },
};
</script>
